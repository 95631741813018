/*=============================================
=            Global Variable Declarations            =
=============================================*/


var navList = document.querySelectorAll('#nav_ul a'),
    pageHeading = document.querySelector('#page-title'),
    switchDiv = document.querySelector('#section-switch'),
    switch_li = document.querySelectorAll('#section-switch li'),
    footerAct = document.querySelector('#footer-act'),
    footerLinks_li = document.querySelectorAll('#ftr-links li'),
    panelAni = document.querySelectorAll('.ani'),
    hamburger = document.querySelector('.hamburger');




/*=====  End of Global Variable Declarations  ======*/



/*=============================================
=            Top Nav Element Animation            =
=============================================*/

function addActive() {
    hamburger.classList.toggle('is-active');
}


var nav_tl = gsap.timeline({
    defaults: {
        duration: 1
    },

});
nav_tl.from(navList, {
    x: -50,
    scale: 0,
    opacity: 0,
    stagger: 0.2,
    duration: 0.5,
    ease: 'back.out(3)',
    pointerEvents: "none",
    onComplete: addActive
}).pause(0);


var page_title = gsap.timeline({
    defaults: {
        duration: 1
    }
});
page_title.from(pageHeading, {
    x: 80,
    scale: 0,
    opacity: 0,
    duration: 0.4,
    ease: 'back.out(1.4)',
    pointerEvents: "none",

});


/*=====  End of Top Nav Element Animation  ======*/


/*=============================================
=          Footer Links Animations            =
=============================================*/


function footer_links_stagger() {

    var footerLinks_tl = gsap.timeline({
        defaults: {
            duration: 0.4
        }
    });
    if (footerAct.classList.contains('active')) {
        footerLinks_tl.fromTo(footerLinks_li, {
            y: 200,
            scale: 0,
            opacity: 0,
            pointerEvents: "none"
        }, {
            y: 0,
            scale: 1,
            opacity: 1,
            stagger: 0.04,
            ease: 'ease-out',
            pointerEvents: "all"
        });
    }
}


/*=====  End of Footer Links Animations  ======*/




/*=============================================
=            Stagger Switch Animation            =
=============================================*/

function switchFlex() {
    switchDiv.style.display = "flex";
}

function switchNone() {
    switchDiv.style.display = "none";
}


function switch_stripes_forward() {

    switchFlex();
    add_switch_overlay();
    var staggerSwitch = gsap.timeline({
        defaults: {
            duration: 0.5,
            // repeat: -1,
            // repeatDelay: -0.1
        }
    });
    staggerSwitch.fromTo(switch_li, {
        xPercent: -100,
    }, {
        visibility: 1,
        xPercent: 100,
        stagger: {
            amount: 0.4,
            from: "top"
        },
        ease: 'ease-out',
        onComplete: switchNone
    });
}

function switch_stripes_reverse() {

    switchFlex();
    add_switch_overlay();
    var staggerSwitch_back = gsap.timeline({
        defaults: {
            duration: 0.5,
            // repeat: -1,
            // repeatDelay: -0.1
        }
    });
    staggerSwitch_back.fromTo(switch_li, {
        xPercent: 100,
    }, {
        visibility: 1,
        xPercent: -100,
        stagger: {
            amount: 0.4,
            from: "top"
        },
        ease: 'ease-out',
        onComplete: switchNone
    });
}



/*=====  End of Stagger Switch Animation  ======*/




/*=============================================
=            Homepage Elements Animations            =
=============================================*/



var tl = gsap.timeline({
    defaults: {
        duration: 1
    }
});
tl.from(".logo-name", {
    x: 200,
    opacity: 0,
    duration: 2,
    ease: "expo.out"
});

tl.from(".homepage-heading", {
    x: -200,
    opacity: 0,
    duration: 2,
    ease: "expo.out"
}, "-=1.2");

tl.from(".logo-element", {
    x: -200,
    scale: 0,
    opacity: 0,
    stagger: 0.1,
    duration: 2,
    rotation: 200,
    ease: "circ.out"
}, "-=2.2");



/*=====  End of Homepage Elements Animations  ======*/
